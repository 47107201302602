<template>
  <div>
    <div class="section">
      <div class="card" :class="item.class?'card-border':''" v-for="item in data" v-bind:key="item.title">
        <h3 v-if="item.title && item.title.length>0">
          {{ item.title }}
        </h3>
        <van-grid v-if="item.imgFlag" :column-num="item.columnNum">
          <van-grid-item v-for="ite in item.child" :key="ite.index" :icon="ite.icon" :text="ite.text"
                         v-if="ite.root"
                         :to="'/'+ite.id" style="color:#557DC3"/>
        </van-grid>
        <van-grid v-else :column-num="item.columnNum">
          <van-grid-item v-for="ite in item.child" :key="ite.index" :to="ite.router" v-if="ite.root">
            <h2 class="not-img-title" :style="{'color':ite.color}">{{ ite.title }}</h2>
            <p class="not-img-text">{{ ite.text }}</p>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MenuCard",
  props: {
    data: {type: Array}
  },
  methods: {
    to(to, id) {
        this.$router.push({path: id})
    },
  }
}
</script>

<style scoped>
.section {
  padding: 5px;
  overflow: hidden;
}

.section .card {
  margin-bottom: 6px;
  padding: 12px 0;
  background-color: #fff;
  border-radius: 12px;
}

.section .card-border {
  box-shadow: #b6c9f1 0 0 5px 2px;
}

.section .card h3 {
  padding-left: 20px;
  margin-top: 4px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
  position: relative;
}

.section .card h3:before {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 5px;
  height: 16px;
  background-color: #ee0a24;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.not-img-title {
  margin-bottom: 6px;
  margin-top: 6px;
  font-weight: 500;
  font-size: 20px;
}

.not-img-text {
  margin: 0;
  font-size: 14px;
  line-height: 26px;
}
</style>
