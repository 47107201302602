<template>
  <div style="background:white">
    <img :src="require('@/assets/szyg.jpg')" style="width: 100%">
    <menu-card :data="this.data"></menu-card>
  </div>
</template>

<script>
import MenuCard from '@/components/Card/MenuCard'

export default {
  name: "DigitalAsset",
  components: {MenuCard},
  data() {
    return {
      data: [
        {
          title: '数字资产',
          columnNum: 2,
          imgFlag: true,
          child: [
            {id: 'FileViewer', icon: 'cluster-o', text: '操作手册', index: 'os1', root: true},
            {id: 'ClassicCase', icon: 'user-circle-o', text: '经典案例', index: '2', root: true},
          ]
        }
      ],
    }
  },
  mounted() {
  },
  methods: {
    to(to, id) {
      if (id == 't1') {
        this.$router.push({path: '/SteelNo'})
      } else if (id == 't2') {
        this.$router.push({path: '/FileViewer'})
      } else {
        this.$router.push({path: '/ClassicCase'})
      }
    },
  }
}
</script>

<style scoped>
.role-row {
  background: #E6E6E6;
  padding: 20px 15px;
  margin: 20px 10px;
  border-radius: 12px;
}

.section {
  padding: 5px;
  overflow: hidden;
}

.section .card {
  margin-bottom: 6px;
  padding: 12px 0;
  background-color: #fff;
  border-radius: 12px;
}

.section .card-border {
  box-shadow: #b6c9f1 0 0 5px 2px;
}

.section .card h3 {
  padding-left: 20px;
  margin-top: 4px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
  position: relative;
}

.section .card h3:before {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 5px;
  height: 16px;
  background-color: #ee0a24;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.not-img-title {
  margin-bottom: 6px;
  margin-top: 6px;
  font-weight: 500;
  font-size: 20px;
}

.not-img-text {
  margin: 0;
  font-size: 14px;
  line-height: 26px;
}
</style>